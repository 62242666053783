<template>
  <v-card>
    <v-navigation-drawer v-model="showDrawer" fixed temporary width="75%" class="mobile-nav">
      <template #prepend>
        <v-card-title class="d-flex justify-space-between pa-2 ma-0">
          <router-link :to="{name : 'Home'}">
            <v-avatar height="65" tile width="auto">
              <img :alt="$apps.name" :src="require('@/assets/images/logo.png')">
            </v-avatar>
          </router-link>

          <v-btn icon color="secondary" outlined @click="showDrawer = !showDrawer">
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <v-divider />

      <!--      <v-card-text>-->
      <v-list tile>
        <MobileNavItem :link="links.home"/>

        <!--products-->
        <v-list-group
          :value="false"
          :prepend-icon="products.icon"
          color="secondary"
        >

          <template v-slot:activator>
            <v-list-item-title>{{ products.title }}</v-list-item-title>
          </template>

          <v-list-group
            :value="false"
            sub-group
            v-for="(category, index) in products.categories"
            :key="`product-category-${index}`"
            color="secondary"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ category.name }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(childCategory, i) in category.children"
              :key="`sub-category${i}`"
              exact
              active-class="unchanged-color"
              exact-active-class="unchanged-color"
              :to="{name: routeMap.products.name, query: {category: category.id, sub : childCategory.id}}"
            >
              <v-list-item-title>{{ childCategory.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

        </v-list-group>

        <MobileNavItem :link="links.vendors"/>
        <MobileNavItem :link="links.blog"/>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn
            block
            color="primary"
            depressed
            v-text="'Login'"
            :to="{name : routeMap.auth.login.name}"
            v-if="!isAuthenticated && !hasAuthenticatedUser"
          />
          <div v-else class="d-flex justify-space-between align-center">
            <v-card-text>{{  authenticatedUser.last_name }}</v-card-text>

            <div>
              <v-menu
                transition="slide-x-transition"
                top
                left
                nudge-top="38px"
                nudge-left="18px"
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="secondary"
                      size="35"
                      class="white--text text-uppercase"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ avatarText(authenticatedUser.full_name) }}

                    </v-avatar>
                </template>
                <v-list>
                  <v-list-item exact :to="{name: routeMap.user.profile.name}" >
                    <v-list-item-icon class="mr-2 mt-3 mb-3">
                      <v-icon> mdi-account </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Profile </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="logout">
                    <v-list-item-icon class="mr-2 mt-3 mb-3">
                      <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Logout </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </template>

    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import routeMap from '@/utils/routeMap';
import MobileNavItem from '@/components/Utils/MobileNavItem';
import { avatarText } from '@/utils/helper';

export default {
  name: 'MobileNavigationDrawer',
  components: { MobileNavItem },
  data: () => ({
    routeMap,
    links: {
      home: {
        title: 'Home',
        routeName: routeMap.home.name,
        link: routeMap.home.path,
        icon: 'mdi-home'
      },
      vendors: {
        title: 'Vendors',
        routeName: routeMap.vendors.name,
        link: routeMap.vendors.path,
        icon: 'mdi-folder'
      },
      blog: {
        title: 'Blog',
        routeName: routeMap.blog.name,
        link: routeMap.blog.path,
        icon: 'mdi-post'
      },
    },

    guestLinks: [
      {
        title: 'Login',
        routeName: routeMap.auth.login.name,
        link: routeMap.auth.login.path,
        icon: 'mdi-login',
      },
    ],

    authLinks: [
      {
        title: 'Profile',
        routeName: routeMap.user.profile.name,
        link: routeMap.user.profile.path,
        icon: 'mdi-account',
      },
    ],

    showDrawer: false,
  }),
  computed: {
    ...mapGetters({
      currentDrawerState: 'app/navigationDrawer',
      hasAuthenticatedUser: 'user/hasData'
    }),

    isAuthenticated() {
      return this.$store.getters['app/isLoggedIn'];
    },

    authenticatedUser(){
      return this.$store.getters['user/data'];
    },

    products(){
      return {
        title: 'Products',
        routeName: routeMap.home.name,
        link: routeMap.products.path,
        icon: 'mdi-view-grid',
        categories: this.$store.getters['home/categoryForMobileNav']
      }
    }
  },
  watch: {
    currentDrawerState: {
      handler(nv) {
        this.showDrawer = nv;
      },
      immediate: true,
    },
    showDrawer: {

      handler(val) {
        if(!val) {
          this.$store.dispatch('app/setNavigationDrawer', val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.showDrawer = this.currentDrawerState;
  },
  methods: {
    async logout() {
      await this.$store.dispatch('app/tryLogout');
      await this.$router.push({ path: '/' });
    },

    avatarText(name) {

      if(!name) {
        return ''
      }

      return avatarText(name[0]);
    }
  }
};
</script>

<style scoped>
.mobile-nav {
  max-width: 350px;
}
</style>
