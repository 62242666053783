<template>
  <div>
    <AppHeader/>

    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      bottom
      color="primary"
      dark
      fab
      fixed
      right
      @click="toTop"
      small
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <MobileNavigationDrawer/>
<!--    <div class="d-block pt-16 d-sm-none d-md-none d-lg-block"></div>-->
    <v-main class="bg-lite pa-0">
      <RouterView #default="{ Component, route }">
        <transition name="scale-slide">
          <component :is="Component" :key="route.path" />
        </transition>
      </RouterView>
    </v-main>

    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '@/layout/AppHeader';
import AppFooter from '@/layout/AppFooter';
import headerNavigation from '@/utils/headerNavigation';
import MobileNavigationDrawer from '@/components/Utils/MobileNavigationDrawer';
import { mapGetters } from 'vuex';

export default {

  name: 'MainLayout',

  components: { MobileNavigationDrawer, AppFooter, AppHeader },

  data: () => ({
    links: headerNavigation,
    drawer: false,
    fab: false
  }),

  methods: {

    onScroll(e) {
      if(typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    }

  },

  computed: {

    ...mapGetters({
      navigationDrawer: 'app/navigationDrawer'
    }),

    overlay() {
      return this.$store.getters['app/loading'];
    },

  },
  watch: {
    navigationDrawer(val) {
      this.drawer = val;
    },

    drawer(val) {
      if(!val) {
        this.$store.dispatch('app/setNavigationDrawer', val);
      }
    }

  },
  mounted() {
    this.drawer = this.navigationDrawer;
  }
};
</script>

<style lang="scss">
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}


.scale-slide-enter-from {
  left: -100%;
}


.scale-slide-enter-to {
  left: 0%;
}


.scale-slide-leave-from {
  transform: scale(1);
}


.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>
